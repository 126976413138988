import moment from 'moment/moment';
import { CookieSetOptions } from 'universal-cookie';

export const FACEBOOK_CALLBACK = process.env.REACT_APP_FACEBOOK_CALLBACK;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const GOOGLE_CAPTCHA = process.env.REACT_APP_GOOGLE_CAPTCHA;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const APP_ENV = process.env.REACT_APP_ENV || 'local';
export const BRO_SITE =
  process.env.REACT_APP_BRO_SITE || 'http://localhost:3000/';
export const ANALYTICS_ENDPOINT =
  process.env.REACT_APP_ANALYTICS_ENDPOINT || '';

export const DOMAIN_SITE = `.${process.env.REACT_APP_DOMAIN || ''}`;

export const APP_ENDPOINT = `${process.env.REACT_APP_ENDPOINT || ''}`;
export const DOMAIN_SITE_URL = `${process.env.REACT_APP_SITE_URL || ''}`;
export const COOKIES_OPTIONS = {
  expires: moment().add(1, 'year').toDate(),
  path: '/',
  domain: DOMAIN_SITE,
};

export const CRITERIO_COOKIES_OPTIONS: CookieSetOptions = {
  expires: moment().add(1, 'year').toDate(),
  path: '/',
  domain:DOMAIN_SITE,

};

export const ANALYTICS_COOKIES_OPTIONS = {
  expires: moment().add(1, 'day').toDate(),
  path: '/',
  domain: DOMAIN_SITE,
};

export const USER_COOKIE = '_u';

export enum PaymentSelection {
  OpenPay = 'OPEN_PAY',
  PayPal = 'PAYPAL',
}

export const GUEST_ROLE = 'GUEST';

export const MAILCHIMP_API_KEY = process.env.REACT_APP_MAILCHIMP_API_KEY || '';
export const MAILCHIMP_SERVER_PREFIX =
  process.env.REACT_APP_MAILCHIMP_SERVER_PREFIX || '';
export const MAILCHIMP_AUDIENCE =
  process.env.REACT_APP_MAILCHIMP_AUDIENCE || '';
