import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { Site } from './types/generated';
import { onError } from '@apollo/client/link/error';
import { OnTokenEvent } from '../modules/auth/auth-events';
import { createUploadLink } from 'apollo-upload-client';
import { easyDataAuthenticateEvent } from '../modules/easy-data/easy-data-events';
import { getRedirectUrl } from '../modules/auth/auth-utils';

export const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_PATH}/graphql`,
});

export const clientUploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_BACKEND_PATH}/graphql`,
});

export const errorLink = onError((error) => {
  if (error.graphQLErrors) {
    // eslint-disable-next-line no-param-reassign
    error.graphQLErrors.map((options) => {
      if (options.message === 'Token expired') {
        return { ...options, message: 'Your session has expired' };
      }

      return options;
    });
  }

  if (error.response) {
    // eslint-disable-next-line no-param-reassign
    error.response.errors?.map((options: { message: string }) => {
      if (options.message === 'Token expired') {
        return { ...options, message: 'Your session has expired' };
      }

      return options;
    });
  }
});

export const authLink = setContext((_, { headers }) => {
  // get the authentication token from event storage if it exists
  const event = OnTokenEvent.get();
  const easyData = easyDataAuthenticateEvent.get();

  if (event?.token) {
    headers = {
      ...headers,
      authorization: event && event.token ? `Bearer ${event.token}` : '',
    };
  }

  if (easyData?.uuid) {
    headers = {
      ...headers,
      'analytics-session': easyData?.uuid,
      'analytics-session-origin': getRedirectUrl() || window.location.href,
    };
  }
  headers = {
    ...headers,
    'easy-wall-site': Site.CriterioHidalgo,
  };

  return {
    headers,
  };
});

export const authLinkUpload = setContext((_, { headers }) => {
  // get the authentication token from event storage if it exists
  const event = OnTokenEvent.get();
  if (event?.token) {
    return {
      headers: {
        ...headers,
        authorization: event && event.token ? `Bearer ${event.token}` : '',
        'Apollo-Require-Preflight': 'true',
      },
    };
  }
  return {
    headers: {
      ...headers,
    },
  };
});

const link = from([authLink, errorLink, httpLink]);

const uploadLink = from([authLinkUpload, errorLink, clientUploadLink]);

/**
 * Import client directly in the actions
 * it's not necessary to add to the event store.
 *
 * If the client is required in a component use the apollo hooks o HOC.
 */
export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export const clientUpload = new ApolloClient({
  link: uploadLink,
  cache: new InMemoryCache(),
});
