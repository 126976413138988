import React from 'react';
import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { useNavigate } from 'react-router-dom';
import { PRIMARY_COLOR } from '../../../css/theme';
import { RoleAuthorization } from '../../../../modules/rbac/RoleAuthorization';
import { RBACPermissions } from '../../../../modules/rbac/rbac-types';
import { openMobileMenu } from './user-actions';
import { openMobileMenuEvent } from './user-events';
import { useStore } from '@cobuildlab/react-simple-state';
import MenuIcon from '@mui/icons-material/Menu';
import { DOMAIN_SITE_URL } from '../../../constans';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    toolbar: {
      backgroundColor: '#333333',
      boxShadow: 'none',
      color: 'black',
    },
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
      color: '#000!important',
      height: '38px !important',
      margin: '0 0',
    },
    menu: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      color: 'black!important',
      fontWeight: 'bold !important',
      border: 'none !important',
      width: '200px !important',
      [theme.breakpoints.down('lg')]: {
        width: '130px !important',
        paddingLeft: '40px',
      },
    },
    textRed: {
      color: PRIMARY_COLOR,
    },
    groupButton: {
      borderBottom: '1px solid #999999!important',
      borderTop: '1px solid #CCCCCC!important',
      padding: '10px 0px 10px 0px',
      display: 'block!important',
    },
    logoClasses: {
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      component: 'div',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        height: '100%',
        component: 'div',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        width: '100%',
        paddingLeft: '40px',
      },
    },
    logoClassesSubscriptor: {
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      component: 'div',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
  }),
);
type HeaderProps = {
  showSidebar?: boolean;
};
export const Header: React.FC<HeaderProps> = ({ showSidebar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { open } = useStore(openMobileMenuEvent);

  return (
    <Box>
      <AppBar>
        <Toolbar className={classes.toolbar}>
          <Box
            sx={{ visibility: { xs: 'hidden', md: 'visible' } }}
            className={classes.logoClasses}
          >
            <a href={DOMAIN_SITE_URL}>
              <img
                src="/logo.png"
                alt="logo"
                width={150}
                height={50}
                style={{ flexGrow: 1, objectFit: 'contain' }}
              />
            </a>
          </Box>
          {showSidebar && (
            <Hidden mdDown={false} mdUp={true}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => openMobileMenu()}
                edge="start"
                sx={{ ...(open && { display: 'none' }) }}
              >
                <MenuIcon style={{ color: 'white' }} />
              </IconButton>
            </Hidden>
          )}
          <Box
            sx={{ visibility: { xs: 'visible', md: 'hidden' } }}
            display={'flex'}
            justifyContent={'center'}
            width={'100%'}
          >
            <a href={DOMAIN_SITE_URL}>
              <img
                src="/logo.png"
                alt="logo"
                width={150}
                height={40}
                style={{ flexGrow: 1, objectFit: 'contain' }}
              />
            </a>
          </Box>
          <Box display={'flex'} justifyContent={'end'} alignItems={'center'}>
            {/** {
              <RoleAuthorization
                render={() => (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disableElevation
                    style={{
                      textTransform: 'capitalize',
                      margin: 'none !important',
                    }}
                    onClick={() => navigate('/planes')}
                  >
                    <Typography fontWeight={700}>
                      <Hidden lgDown={true} lgUp={false}>
                        Quiero{' '}
                      </Hidden>
                      acceso total
                    </Typography>
                  </Button>
                )}
                permission={RBACPermissions.CAN_SUBSCRIPTION}
                error={() => null}
              />
            } */}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
