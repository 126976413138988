import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { BRO_SITE, COOKIES_OPTIONS, USER_COOKIE } from '../../shared/constans';
import { getTokenUrl } from '../auth/auth-utils';
import { redirectToHome } from '../../shared/utils';

export const UrlAuth: React.FC = () => {
  //const [tokenAux, setTokenAux] = useState('');
  const [, setCookie] = useCookies([USER_COOKIE, 'analytics_uuid']);
  useEffect(() => {
    const token = getTokenUrl();
    if (token) {
      setCookie(USER_COOKIE, token, COOKIES_OPTIONS);
      // setTokenAux(token);
    }
    redirectToHome(BRO_SITE);
    //goBackToHome('/');
  }, []);
  return (
    <Box
      height={'100vh'}
      width={'100%'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Typography fontSize={18} fontWeight={700} fontFamily={'BentonsansBold'}>
        Conectando a www.criteriohidalgo.com
      </Typography>
    </Box>
  );
};
