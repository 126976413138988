export const SETTING_ROUTES = [
  {
    path: '/',
    name: 'Perfil',
  },
  // {
  //   path: '/facturas',
  //   name: 'Facturas',
  // },
  // {
  //   path: '/suscripciones',
  //   name: 'Suscripciones',
  // },
  /**
   * {
    path: '/mis-notas-guardadas',
    name: 'Mis notas guardadas',
  },
   */
  // {
  //   path: '/detalles-de-facturacion',
  //   name: 'Detalles de facturacion',
  // },
  {
    path: '/notificaciones',
    name: 'Notificaciones',
  },
  // {
  //   path: '/newsletters',
  //   name: 'Newsletters',
  // },

  /**
   * {
    path: '/convoy',
    name: 'Convoy',
  },
   */
];
